<template>
    <base-table models-name="tipologie"
                model-ref="la tipologia"
                new-msg="una nuova tipologia"
                :is-loading="isLoading"
                :headers="headers"
                :can-create="$store.getters.isSystem"
                @changeOptions="onChangeOptions($event)"
    >

        <template #header="{ onChangeFilter }">
            <tipologie-table-header @changeOptions="onChangeOptions($event)"
                                    @changeFilter="onChangeFilter"
            ></tipologie-table-header>
        </template>

        <template #IS_PERCORSO="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small>fa fa-{{ !item.IS_PERCORSO ? 'calendar-alt' : 'route' }}</v-icon>
                </template>
                <span>{{ !item.IS_PERCORSO ? 'EVENTO' : 'PERCORSO' }}</span>
            </v-tooltip>
        </template>

        <template #NOME="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <i v-on="on">{{ getTesto(item).NOME }} (<strong>{{ getTesto(item).LNG_SIGLA }}</strong>)</i>
                </template>
                <span>{{ getTesto(item).DESCRIZIONE || 'No data available' }}</span>
            </v-tooltip>
        </template>

        <template #EV_COUNT="{ item }">
            <button-tooltip x-small
                            fab
                            :text="''+item.EV_COUNT"
                            color="primary"
                            :to="'/secure/' + (item.IS_PERCORSO ? 'percorsi' : 'eventi') + '?tpl=' + item.ID_REC"
                            exact
                            :tooltip="getTesto(item).NOME + ' possiede ' + item.EV_COUNT + ' eventi associati'"
            ></button-tooltip>
        </template>

    </base-table>
</template>

<script>
    import BaseTable from '@/components/aaaProject/table/BaseTable';
    import TipologieTableHeader from '@/components/secure/tipologie/components/TipologieTableHeader';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';

    export default {
        name: 'TipologieTable',
        components: {ButtonTooltip, TipologieTableHeader, BaseTable},
        props: {isLoading: Boolean, idLinguaFk: Number},
        data: () => ({
            headers: [
                {
                    text: '#',
                    value: 'ID_REC',
                    align: 'center'
                },
                {
                    text: 'Stato ',
                    value: 'ERRORE',
                    align: 'center'
                },
                {
                    text: 'Tipo ',
                    value: 'IS_PERCORSO',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Nome ',
                    value: 'NOME',
                    align: 'center',
                    sortable: false,
                    custom: true
                },
                {
                    text: 'Eventi ',
                    value: 'EV_COUNT',
                    align: 'center',
                    custom: true
                },
                {
                    text: 'Visualizzazioni ',
                    value: 'VISUALIZZAZIONI',
                    align: 'center'
                },
                {
                    value: 'actions',
                    align: 'center',
                    sortable: false
                }
            ]
        }),
        methods: {
            onChangeOptions(options) {
                this.$emit('changeOptions', options);
            },
            getTesto(tipologia) {

                let testo = tipologia.testi.find(t => t.ID_LINGUA_FK === this.idLinguaFk);

                if (!testo) {
                    testo = tipologia.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = tipologia.testi[0];
                }

                return testo;

            }
        }
    }
</script>

<style scoped>

</style>
