var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{attrs:{"models-name":"tipologie","model-ref":"la tipologia","new-msg":"una nuova tipologia","is-loading":_vm.isLoading,"headers":_vm.headers,"can-create":_vm.$store.getters.isSystem},on:{"changeOptions":function($event){return _vm.onChangeOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var onChangeFilter = ref.onChangeFilter;
return [_c('tipologie-table-header',{on:{"changeOptions":function($event){return _vm.onChangeOptions($event)},"changeFilter":onChangeFilter}})]}},{key:"IS_PERCORSO",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("fa fa-"+_vm._s(!item.IS_PERCORSO ? 'calendar-alt' : 'route'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(!item.IS_PERCORSO ? 'EVENTO' : 'PERCORSO'))])])]}},{key:"NOME",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('i',_vm._g({},on),[_vm._v(_vm._s(_vm.getTesto(item).NOME)+" ("),_c('strong',[_vm._v(_vm._s(_vm.getTesto(item).LNG_SIGLA))]),_vm._v(")")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTesto(item).DESCRIZIONE || 'No data available'))])])]}},{key:"EV_COUNT",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"x-small":"","fab":"","text":''+item.EV_COUNT,"color":"primary","to":'/secure/' + (item.IS_PERCORSO ? 'percorsi' : 'eventi') + '?tpl=' + item.ID_REC,"exact":"","tooltip":_vm.getTesto(item).NOME + ' possiede ' + item.EV_COUNT + ' eventi associati'}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }