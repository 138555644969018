<template>
    <base-sezione models-name="tipologie" no-padding>

        <template #table="{ isLoading, onChangeOptions }">

            <loading-resouces v-if="isLingueLoading"
                              icon="fa-flag"
                              messaggio="Recupero delle lingue in corso"
            ></loading-resouces>

            <tipologie-table v-else-if="Boolean(idLinguaFk)"
                             :is-loading="isLoading"
                             :id-lingua-fk="idLinguaFk"
                             @changeOptions="onChangeOptions"
            ></tipologie-table>

            <no-data-available v-else models-name="lingue" type="lingue"></no-data-available>

        </template>

        <template #header>

            <v-spacer></v-spacer>
            <h-col cols="12" sm="auto">
                <base-select-lingue v-model="idLinguaFk" @finish="isLingueLoading = false"></base-select-lingue>
            </h-col>

        </template>

    </base-sezione>
</template>

<script>
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseSezione from '@/components/aaaProject/commons/BaseSezione';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import TipologieTable from '@/components/secure/tipologie/components/TipologieTable';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';
    import BaseSelectLingue from '@/components/aaaProject/table/api/BaseSelectLingue';

    export default {
        name: 'Tipologie',
        components: {HCol, BaseSelectLingue, NoDataAvailable, LoadingResouces, TipologieTable, BaseSezione},
        data: () => ({
            isLingueLoading: true,
            idLinguaFk: undefined
        })
    }
</script>

<style scoped>

</style>
