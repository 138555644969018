<template>
    <h-row>

        <h-col cols="12" sm="6">
            <base-table-header-select label="Tipo"
                                      key-options="isPercorso"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: 'EVENTO', value: false},
                                          {label: 'PERCORSO', value: true}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>

        <h-col cols="12" sm="6">
            <base-table-header-select label="Stato"
                                      key-options="errore"
                                      :items="[
                                          {label: '--Nessun Filtro--', value: null},
                                          {label: 'ATTIVO', value: 'NONE'},
                                          {label: 'INATTIVO', value: 'NOT_NONE'},
                                          {label: 'DISATTIVATO MANUALMENTE', value: 'SELF'},
                                          {label: 'TESTO DEFAULT MANCANTE', value: 'NO_TEXT_DEFAULT'}
                                      ]"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-select>
        </h-col>

        <h-col cols="12">
            <base-table-header-filter exact
                                      @changeFilter="$emit('changeFilter', $event)"
                                      @changeOptions="$emit('changeOptions', $event)"
            ></base-table-header-filter>
        </h-col>

    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTableHeaderSelect from '@/components/aaaProject/table/BaseTableHeaderSelect';
    import BaseTableHeaderFilter from '@/components/aaaProject/table/BaseTableHeaderFilter';

    export default {
        name: 'TipologieTableHeader',
        components: {HRow, HCol, BaseTableHeaderFilter, BaseTableHeaderSelect}
    }
</script>

<style scoped>

</style>
